import 'bootstrap/dist/css/bootstrap.min.css';
import  "./css/table.css";
import "rsuite/dist/rsuite.min.css";
import  "../../src/gitprofile/dist/style.css";
import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from '@vercel/speed-insights/next';

export default function MyApp({ Component, pageProps }) {
    return <><Analytics /><SpeedInsights /><Component {...pageProps} /></>;
};

